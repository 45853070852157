<template>
    <div class="pageContol">
        <div class="framePage">
            <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">精品课程</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">课程方列表</a>
        </span>
            </div>
            <div class="framePage-body">
                <div class="operationControl flexdc" style="align-items:flex-start">
                    <div class="searchbox" style="margin-bottom:5px">
                        <div title="姓名" class="searchboxItem ci-full">
                            <span class="itemLabel">姓名:</span>
                            <el-input
                                    v-model="name"
                                    type="text"
                                    size="small"
                                    placeholder="请输入姓名"
                                    clearable
                            />
                        </div>
                        <div title="电话" class="searchboxItem ci-full">
                            <span class="itemLabel">电话:</span>
                            <el-input
                                    v-model="phone"
                                    type="text"
                                    size="small"
                                    placeholder="请输入电话"
                                    clearable
                            />
                        </div>
                        <el-button class="bgc-bv" style="margin:0 10px" round @click="getData()">查询</el-button>
                        <el-button class="bgc-bv" style="margin:0 10px" round @click="courseAdd">新增</el-button>
                    </div>
                </div>
                <div class="framePage-scroll">
                    <div class="ovy-a">
                        <el-table
                                ref="multipleTable"
                                :data="tableData"
                                :height="tableHeight"
                                size="small"
                                tooltip-effect="dark"
                                style="width: 100%"
                                :header-cell-style="tableHeader"
                                stripe
                        >
                            <el-table-column label="序号" align="center" type="index" :index="indexMethod" fixed
                                             width="100"/>
                            <el-table-column
                                    label="姓名"
                                    align="left"
                                    prop="name"
                                    show-overflow-tooltip
                                    width="280"
                            />
                            <el-table-column
                                    label="电话"
                                    align="left"
                                    show-overflow-tooltip
                                    prop="phone"
                            />

                            <el-table-column
                                    label="课程数量(个)"
                                    align="left"
                                    prop="courseCount"
                                    show-overflow-tooltip
                            />
                            <el-table-column
                                    label="订单量(个)"
                                    align="left"
                                    prop="orderCount"
                                    show-overflow-tooltip
                            />
                            <el-table-column
                                    label="添加时间"
                                    align="left"
                                    show-overflow-tooltip
                                    prop="createTime"
                                    fixed="right"
                                    value-format='yyyymmdd'
                            />
                            <el-table-column label="操作" fixed="right" align="left" width="120px">
                                <div slot-scope="scope">
                                    <el-button
                                            type="text"
                                            style="padding:0px 5px"
                                            size="mini"
                                            @click="courseAdd(scope.row)"
                                    >编辑
                                    </el-button>
                                </div>
                            </el-table-column>
                            <Empty slot="empty"/>
                        </el-table>
                    </div>
                </div>
                <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData"/>
            </div>
        </div>
        <el-dialog
                :title="ownform.title?'编辑':'新增'"
                :visible.sync="docsDialogVisible"
                width="600px"
                top="5%"
                :center="true"
                :before-close="cancel"
        >
            <el-form

                    ref="ownform"
                    :model="ownform"
                    style="width:100%"
                    prop="ownform"
                    :rules="rules"
            >
                <div style="text-align: center;margin: 0 auto;width: 90%">
                    <el-form-item label="姓名：" label-width="100px" prop="name">
                        <el-input v-model="ownform.name" size="small" show-word-limit></el-input>
                    </el-form-item>
                    <el-form-item label="电话：" label-width="100px" prop="phone">
                        <el-input v-model="ownform.phone" size="small" show-word-limit></el-input>
                    </el-form-item>
                    <el-form-item
                            label="头像"
                            prop="thumbnail"
                            :class="[rotationId ? 'item-disabled' : '']"
                            label-width="100px"
                            required
                    >
                        <el-upload
                                :on-change="handleAvatarSuccess2"
                                :before-upload="beforeAvatarUpload2"
                                :http-request="$requestMine"
                                :show-file-list="false"
                                class="img-el-upload"
                                action
                                accept="image/png, image/gif, image/jpg, image/jpeg"
                        >
                            <el-image
                                    :src="ownform.thumbnail || require('@/assets/develop.png')"
                                    fit="contain"
                                    class="imgCenter"
                            ></el-image>
                        </el-upload>
                        <p
                                style="color:#f46173;line-height:25px;"
                        >请上传 640*357 （长 X 宽）像素的PNG,JPG格式的图片，且文件大小不能超过1MB！</p>
                    </el-form-item>
                    <el-form-item label="简介：" label-width="100px" prop="introduction" required>
                        <el-input type="textarea" v-model="ownform.introduction" size="small"
                                  :rows="5"
                                  show-word-limit></el-input>
                    </el-form-item>
                </div>
            </el-form>
            <span slot="footer" class="dialog-footer" style="display: flex; justify-content: center;">
        <div>
          <el-button @click="cancel">取消</el-button>
          <el-button class="bgc-bv" @click="addEdit(ownform)">确定</el-button>
        </div>
      </span>
        </el-dialog>
    </div>
</template>
<script>
    import Empty from "@/components/Empty.vue";
    import PageNum from "@/components/PageNum.vue";
    import List from "@/mixins/List";
    import {resetKeepAlive} from "@/utils/common";

    export default {
        name: "koniptList",
        components: {
            Empty,
            PageNum,
        },
        mixins: [List],
        data() {
            var Phone = (rule, value, callback) => {
                if (!value) {
                    callback();
                } else if (
                    value &&
                    !/^1[3456789]\d{9}$/.test(value) &&
                    !/^([0-9]{3,4}-)?[0-9]{7,8}$/.test(value)
                ) {
                    callback(new Error("请输入正确的手机号码"));
                } else {
                    callback();
                }
            };
            return {
                id:"",
                courseOwnerId: '',
                phone: '',//电话号
                name: '',//姓名
                createTime: '',//添加时间
                docsDialogVisible: false,
                kpointName: "",
                projectName: "",
                saleState: "",
                params: {},
                ruleForm: {
                    Trainingtype: ""
                },
                salestatelist: [],
                CompanyList: [],
                compId: "",
                publicKpoint: "",
                kpointTrySee: "",
                ownform: {
                    title: false,
                    phone: '',
                    name: '',
                    introduction: '',
                    thumbnail: "", //图片地址
                    fileKey: "", //上传的图片
                    advisoryPath: "", // 内容链接
                },
                rules: {
                    name: [
                        {required: true, message: '请输入姓名', trigger: 'blur'},
                        { min: 1, max: 8, message: '长度在 1 到 8 个字符', trigger: 'blur' }
                    ],
                    phone: [
                        {required: true, validator: Phone, trigger: "blur"},
                    ],
                    thumbnail: [
                        {required: true, message: '请上传头像', trigger: 'blur'}
                    ],
                    introduction: [
                        {required: true, message: '请填写简介', trigger: 'blur'},
                        { min: 1, max: 200, message: '长度在 1到 200 个字符', trigger: 'blur' }

                    ]
                }
            };


        },
        mounted() {
            this.getTableHeight();
        },
        created() {
            /* this.getcompanytypeList();*/
            this.getData();
        },
        computed: {},
        methods: {

            // 弹窗点击取消
            cancel() {
                this.$refs['ownform'].resetFields();
                this.$nextTick(() => {
                    if (this.$refs['ownform'] != undefined) {
                        this.$refs['ownform'].resetFields();

                    }
                });
                this.ownform.name = '';
                this.ownform.phone = '';
                this.ownform.fileKey = '';
                this.ownform.thumbnail = '';
                this.ownform.introduction = "";
                this.docsDialogVisible = false;
            },
            //弹窗点击确定
            addEdit(ownform) {
                this.$refs.ownform.validate((valid) => {
                    if (valid) {
                        //编辑修改
                        if(this.id){
                            let data = {
                                name: ownform.name,//名称
                                phone: ownform.phone,//电话
                                introduction: ownform.introduction,//简介
                                courseOwnerId: this.id,//课程方id
                            };
                            if (this.ownform.fileKey) {
                                data.avatarKey = this.ownform.fileKey;
                            }
                            this.$post("/biz/ec/course/owner/modify", data, 3000, true, 2)
                                .then(res => {
                                    if (res.status == "0") {
                                        setTimeout(() => {
                                            this.$message({
                                                type: "success",
                                                message: "修改成功"
                                            });
                                        }, 300);
                                        this.ownform.name = '';
                                        this.ownform.phone = '';
                                        this.ownform.fileKey = '';
                                        this.ownform.thumbnail = '';
                                        this.ownform.introduction = "";
                                        this.docsDialogVisible = false;
                                        this.getData();
                                    }
                                })
                                .catch(() => {
                                    setTimeout(() => {
                                        this.$message({
                                            type: "warning",
                                            message: "上传失败"
                                        });
                                    }, 300);
                                });
                        }else{
                            //新增
                            let data = {
                                name: ownform.name,//名称
                                phone: ownform.phone,//电话
                                avatarKey: ownform.fileKey,//头像key
                                introduction: ownform.introduction//简介
                            };
                            this.$post("/biz/ec/course/owner/insert", data, 3000, true, 2)
                                .then(res => {
                                    if (res.status == "0") {
                                        this.$message({
                                            type: "success",
                                            message: "添加成功"
                                        });
                                        this.ownform.name = '';
                                        this.ownform.phone = '';
                                        this.ownform.fileKey = '';
                                        this.ownform.thumbnail = '';
                                        this.ownform.introduction = "";
                                        this.docsDialogVisible = false;
                                        this.getData();
                                    }
                                })
                                .catch(() => {});
                        }
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            //图片上传
            handleAvatarSuccess2(res) {
                let extension = res.name.substring(res.name.lastIndexOf(".") + 1);
                let formData = new FormData();
                formData.append("folder ", "COURSE_WARE");
                formData.append("file ", res.raw);
                formData.append("fileType ", extension);
                formData.append("isPublic ", true);
                const isLt2M = res.size / 1024 / 1024 < 1;
                if (!isLt2M) {
                    this.$message.error("上传图片大小不能超过 1MB!");
                } else if (
                    ["image/png", "image/gif", "image/jpg", "image/jpeg"].indexOf(
                        res.raw.type
                    ) === -1
                ) {
                    this.$message.error("请上传正确的图片格式");
                    return false;
                } else {
                    this.$Postformat("/sys/upload", formData)
                        .then(result => {
                            this.ownform.thumbnail = result.data.fileURL || "";
                            this.ownform.fileKey = result.data.fileKey || "";
                        })
                        .catch(() => {
                            setTimeout(() => {
                                this.$message({
                                    type: "warning",
                                    message: "上传失败"
                                });
                            }, 300);
                        });
                }
            },
            getData(pageNum = 1) {
                const params = {
                    pageNum: pageNum,
                    pageSize: this.pageSize,
                    name: this.name || '',
                    phone: this.phone || ''
                };
                this.doFetch({
                    url: "/biz/ec/course/owner/listPage",
                    params,
                    pageNum
                },true,2);
            },
            //新增/编辑
            courseAdd(row) {
                if (row.courseOwnerId) {
                    this.ownform.title = true;
                    this.ownform.name = row.name;
                    this.ownform.phone = row.phone;
                    this.ownform.introduction = row.introduction;
                    this.ownform.thumbnail = row.avatarKey;
                    this.id = row.courseOwnerId;
                } else {
                    this.ownform.title = false;
                    this.ownform.name = '';
                    this.ownform.phone = '';
                    this.ownform.introduction = '';
                    this.ownform.thumbnail = '';
                    this.id = '';
                    this.$nextTick(() => {
                        if (this.$refs['ownform'] != undefined) {
                            this.$refs['ownform'].resetFields();
                        }
                    });
                }
                this.docsDialogVisible = true;
            },
            getTableHeight(opDom = true, page = true) {
                let tHeight =
                    window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 3) * 12;
                if (opDom) {
                    tHeight -= 40 + 0.675 * 16 + 1;
                }
                if (page) {
                    tHeight -= 32;
                }
                this.tableHeight = tHeight;
            },
        },
        watch: {
            $route: {
                handler: function (val, oldVal) {
                    if (val.query.refresh == true) {
                        this.getData(-1);
                    }
                },
                // 深度观察监听
                deep: true
            }
        }
    };
</script>
<style lang="less">
</style>
